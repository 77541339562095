import React, { useEffect ,useState } from 'react';
import axios from 'axios';
import randomstring from 'randomstring';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
function PaymentForm() {

  let [tokens , setTokens] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('');
  const [retry, setRetry] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const token = queryParams.get('token');
  // Using useEffect to handle side effects
  
useEffect(() => {
    if (type === 'RestuarantFee') {
      const restaurantId = queryParams.get('restaurantId');
      const amount = queryParams.get('paymentAmount');
      // const addId = queryParams.get('addId');
      // const businessId = queryParams.get('businessId');
      // const token = queryParams.get('token');

      setPaymentAmount(amount);

      const restuarantFee = {
        restaurantId,
        paymentAmount: amount,
        // addId,
        // businessId,
        type
      };
      console.log(restuarantFee)
      // Storing the subscription object in local storage
      localStorage.setItem('transactionDetails', JSON.stringify(restuarantFee));
    } else if (type === 'FeauturedFee') {
      const restaurantId = queryParams.get('restaurantId');
      const amount = queryParams.get('paymentAmount');
      const featureId = queryParams.get('featureId');

      setPaymentAmount(amount);

      const featuredFee = {
        restaurantId,
        paymentAmount: amount,
        featureId,
        type
      };
      console.log(featuredFee)
      // Storing the voucher object in local storage
      localStorage.setItem('transactionDetails', JSON.stringify(featuredFee));
    }
  }, [location.search]);

 

  const plan = { price: paymentAmount }; 
  // Example price
  console.log(plan)
  const today = new Date(); // Current date

const randomString = randomstring.generate();  

// async function getAccessToken() {
//   try {
//     const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";

//     const merchantId = 21288;
//     const securedKey = "pjjfklqXKUP-XHSCC0onJa3qf";

//     const response = await axios.post(`${BASE_URL}/token`, {
//       MERCHANT_ID : merchantId,
//       SECURED_KEY : securedKey,
//       BASKET_ID : '',
//       TXNAMT: '',
//     });

//     setTokens(response?.data?.ACCESS_TOKEN)
//     console.log(tokens);
 
//     console.log(response);
//     // alert("Token Fetched Successfully")
//     return response;
//   } catch (error) {
//     console.log(error.message);
//     setRetry(prev => prev + 1); // Increment retry to trigger useEffect
//   }
// }
   
// async function getAccessToken() {
//   try {
//     const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";
//     const merchantId = 21288;
//     const securedKey = "pjjfklqXKUP-XHSCC0onJa3qf";

//     const response = await axios.post(`${BASE_URL}/token`, {
//       MERCHANT_ID: merchantId,
//       SECURED_KEY: securedKey,
//       BASKET_ID: '',
//       TXNAMT: '',
//     }, {
//       headers: {
//         'Content-Type': 'application/json',
//         // Additional headers if required
//       },
//       timeout: 10000, // 10 seconds timeout
//     });

//     setTokens(response?.data?.ACCESS_TOKEN);
//     console.log(tokens);
//     console.log(response);
//     // alert("Token Fetched Successfully");
//     return response;
//   } catch (error) {
//     console.error("Error fetching token:", error.message);
//     setRetry(prev => prev + 1); // Increment retry to trigger useEffect
//   }
// }

// async function getAccessToken() {
//   try {
//     const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";
//     const merchantId = 21288;
//     const securedKey = "pjjfklqXKUP-XHSCC0onJa3qf";

//     console.log('Fetching access token using fetch...');
//     const response = await fetch(`${BASE_URL}/token`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         MERCHANT_ID: merchantId,
//         SECURED_KEY: securedKey,
//         BASKET_ID: '',
//         TXNAMT: '',
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const data = await response.json();
//     const accessToken = data?.ACCESS_TOKEN;

//     if (accessToken) {
//       setTokens(accessToken);
//       console.log('Access token fetched:', accessToken);
//       // alert("Token Fetched Successfully");
//     } else {
//       throw new Error('No access token in response');
//     }
//     return data;
//   } catch (error) {
//     console.error("Error fetching token:", error.message);
//     setRetry(prev => prev + 1); // Increment retry to trigger useEffect
//   }
// }


// useEffect(() => {
  // if (!tokens) {
    // getAccessToken();
  // }
  // Only run once
// });


// Additional utility function to handle retries if needed
// const retryFetchingToken = () => {
//   setTimeout(() => {
//     getAccessToken();
//   }, 5000); // Retry after 5 seconds
// };

// useEffect(() => {
//   if (retry > 0) {
//     retryFetchingToken();
//   }
// }, [retry]);

 
const inputStyle = {
  border: '2px solid #C40C0C',
  borderRadius: '5px',
  padding: '10px',
  fontSize: '16px',
  transition: 'border-color 0.3s, box-shadow 0.3s',
};

const handleFocus = (e) => {
  e.target.style.borderColor = '#C40C0C';
  e.target.style.boxShadow = '0 0 10px rgba(0, 123, 255, 0.25)';
};

const handleBlur = (e) => {
  e.target.style.borderColor = '#C40C0C';
  e.target.style.boxShadow = 'none';
};

  return (
    <div className="card col-12">
      <h5 style={{ marginLeft: '20px' }} className="mt-3">Your details</h5>
      <form
        id="PayFast_payment_form"
        name="PayFast-payment-form"
        method="post"
        action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction"
      >
        <input type="hidden" name="CURRENCY_CODE" value="PKR" />
        <input type="hidden" name="MERCHANT_ID" value="21288" />
        <input type="hidden" name="TOKEN" value={token} />
        <input type="hidden" name="SUCCESS_URL" value="http://foodpayment.ashhkaro.com/Success" />
        <input type="hidden" name="FAILURE_URL" value="http://foodpayment.ashhkaro.com/Failed" />
        <input type="hidden" name="CHECKOUT_URL" value="https://typedwebhook." />


        {/* <div className="d-flex justify-content-between align-items-center mt-4">
          <div className="col-5" style={{ marginLeft: '40px' }}>
            <label>Customer Number</label>
            <input type="text" className="form-control" name="CUSTOMER_MOBILE_NO" placeholder="123456789" required />
          </div>
          <div className="col-5" style={{ marginRight: '40px' }}>
            <label>Customer Email</label>
            <input type="text" className="form-control" name="CUSTOMER_EMAIL_ADDRESS" placeholder="your@gmail.com" required />
          </div>
        </div> */}

      
        <div className="container">
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4" style={{ marginLeft: '20px', marginRight: '20px' }}>
        <div className="col-12 col-md-5 mb-3 mb-md-0" style={{ marginLeft: '20px', marginRight: '20px' }}>
          <label style={{ fontWeight: 'bold', color: '#4a4a4a' }}>Customer Number</label>
          <input
            type="text"
            className="form-control"
            name="CUSTOMER_MOBILE_NO"
            placeholder="123456789"
            required
            style={inputStyle}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
        <div className="col-12 col-md-5" style={{ marginLeft: '20px', marginRight: '20px' }}>
          <label style={{ fontWeight: 'bold', color: '#4a4a4a' }}>Customer Email</label>
          <input
            type="text"
            className="form-control"
            name="CUSTOMER_EMAIL_ADDRESS"
            placeholder="your@gmail.com"
            required
            style={inputStyle}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </div>

        <input type="hidden" name="TXNAMT" value={plan.price} />
        <input type="hidden" name="BASKET_ID" value="2910" />
        <input type="hidden" name="ORDER_DATE" value={`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`} />
        <input type="hidden" name="SIGNATURE" value={randomString} />
        <input type="hidden" name="VERSION" value={randomString} />
        <input type="hidden" name="TXNDESC" value="Item Purchased from Cart" />
        <input type="hidden" name="PROCCODE" value="00" />
        <input type="hidden" name="TRAN_TYPE" value="ECOMM_PURCHASE" />
        <input type="hidden" name="STORE_ID" value="" />

        <input type="hidden" id="RECURRING_TXN" name="RECURRING_TXN" value="true" />
        <input type="hidden" name="ITEMS[0][SKU]" value="SAMPLE-SKU-01" />
        <input type="hidden" name="ITEMS[0][NAME]" value="An Awesome Dress" />
        <input type="hidden" name="ITEMS[0][PRICE]" value="150" />
        <input type="hidden" name="ITEMS[0][QTY]" value="2" />
        <input type="hidden" name="ITEMS[1][SKU]" value="SAMPLE-SKU-02" />
        <input type="hidden" name="ITEMS[1][NAME]" value="Ice Cream" />
        <input type="hidden" name="ITEMS[1][PRICE]" value="45" />
        <input type="hidden" name="ITEMS[1][QTY]" value="5" />

        <div style={{ display: 'flex', justifyContent: 'center',  height: '100vh' }}>
  <div style={{ padding: '10px', borderRadius: '5px' }}>
    <input 
      type="submit" 
      className="btn btn-theme mb-3" 
      value="SUBMIT" 
      style={{ 
        padding: '10px 20px', 
        color: '#fff', 
        backgroundColor: '#C40C0C', 
        border: 'none', 
        borderRadius: '5px', 
        cursor: 'pointer',
        fontSize: '16px',
        margin: '5px' 
      }} 
    />
  </div>
</div>


      </form>
    </div>
  );
}

export default PaymentForm;
