import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Main(){

let  [tokens, setTokens] = useState('')

async function getAccessToken() {
  try {
    const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";

    const merchantId = 21288;
    const securedKey = "wNQ3VofFZIiDK7Ke4P6J8eO";

    const response = await axios.post(`${BASE_URL}/token`, {
      MERCHANT_ID : merchantId,
      SECURED_KEY : securedKey,
      BASKET_ID : '',
      TXNAMT: '',
    });

    setTokens(response?.data?.ACCESS_TOKEN)
    console.log(tokens);
 
    console.log(response);
    // alert("Token Fetched Successfully")
    return response;
  } catch (error) {
    console.log(error.message);
    // setRetry(prev => prev + 1); // Increment retry to trigger useEffect
  }
}
   useEffect (()=>{
    getAccessToken()
   },[])


    const queryParamsRestaurantFee = new URLSearchParams(
      {
         paymentAmount: '1',  
         restaurantId:"123",
         type:'RestuarantFee',
         token:tokens
       }).toString();

      //  paymentAmount same which stored in restaurant fee
  
      
    const   queryParamsFeatured = new URLSearchParams({ 
    
        paymentAmount: '', 
        restaurantId : '',
        type:'FeauturedFee' ,
        featureId:'',
        token:tokens
      }).toString();
        
    return(
        <div className="App">
    <h1>

      {/* Hello  */}

    </h1>
    Link to the route with parameters (Restaurant)
    <Link to={`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsRestaurantFee}`}> 
    Go to Your Component with Parameters (RestaurantFee)
    </Link>
    <br/>
    <br/>
    <Link to={`http://foodpayment.ashhkaro.com/PaymentForm?${queryParamsFeatured}`}>
    Go to Your Component with Parameters (Featured)
    </Link>

    </div>
    )
}

export default Main;